* {
  margin: 0;
  padding: 0;
}
.overlay .menu-menu-container{
    /*height: 100%;*/
}

/* Icon 1 */

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 20px;
  height: 18px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 10000;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .50s ease-in-out;
  -moz-transition: .50s ease-in-out;
  -o-transition: .50s ease-in-out;
  transition: .50s ease-in-out;
}


/* Icon 4 */

#nav-icon4.open {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
}

#nav-icon4 span:nth-child(2) {
  top: 2px;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}

#nav-icon4 span:nth-child(3) {
  top: 4px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(4) {
  top: 10px;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}

#nav-icon4.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 8px;
  left: 0px;
  background: #000;
}

#nav-icon4.open span:nth-child(3) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(4) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 4px;
  left: 0px;
  background: #000;
}

.navbar-toggle {
  position: static;
  width: 20px;
  height: 20px;
  float: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 0 solid transparent;
  border-radius: 0;
}

/* Change the Bootstrap NavBar Breakpoint */

@media (max-width: 992px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}


/* Overlay style */
.overlay,
.overlay2 {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #FFFFFF;
    z-index: 9999;
}
.menu-header_menu-container{
    height: 100%;
}
/* Overlay closing cross */
.overlay .overlay-close {
	width: 80px;
	height: 80px;
	position: absolute;
    right: 0px;
    top: 55px;
	overflow: hidden;
	border: none;
	background: transparent;
	text-indent: 200%;
	color: transparent;
	outline: none;
	z-index: 10000;
}

/* Menu style */
.overlay nav {
	text-align: center;
	position: relative;
	top: 165px;
	font-size: 1.5em;
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: 100%;
	position: relative;
}

.overlay ul li {
	display: block;
	min-height: 36px;
}

.overlay ul li a {
    font-weight: 700;
    display: inline-block;
    color: #000000;
    border-bottom: 3px solid #000000;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    line-height: 60px;
    width: 100%;
    text-align: left;
    padding-left: 1em;
}
.overlay ul li a .svg-wrapper {
    -webkit-transition: all 0.5s;
    transition:  all 0.5s;
    width: 40px;
}
.overlay ul li a:hover .svg-wrapper {
    fill:rgb(200, 150, 80) !important;
}
.overlay2 nav {
	text-align: center;
	position: relative;
	top: 165px;
	font-size: 1.5em;
}

.overlay2 ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: 100%;
	position: relative;
}

.overlay2 ul li {
	display: block;
	min-height: 36px;
}

.overlay2 ul li a {
    font-weight: 700;
    display: inline-block;
    color: #000000;
    border-bottom: 3px solid #000000;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    line-height: 60px;
    width: 100%;
    text-align: left;
    padding-left: 1em;
}
.overlay2 ul li a .svg-wrapper {
    -webkit-transition: all 0.5s;
    transition:  all 0.5s;
    width: 40px;
}
.overlay2 ul li a:hover .svg-wrapper {
    fill:rgb(200, 150, 80) !important;
}
.overlay2 .overlay2-close {
	width: 80px;
	height: 80px;
	position: absolute;
    right: 0px;
    top: 55px;
	overflow: hidden;
	border: none;
	background: transparent;
	text-indent: 200%;
	color: transparent;
	outline: none;
	z-index: 10000;
}
.wpml-ls-legacy-list-vertical{
    width: 100%;
}

/* Effects */
.overlay-hugeinc {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
    z-index: 1;
}

.overlay-hugeinc.open {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.overlay-hugeinc nav ul {
	opacity: 0.4;
	-webkit-transform: translateY(-25%) scale(1);
	transform: translateY(-25%) scale(1);
	-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
	transition: transform 0.5s, opacity 0.5s;
    width: 100%;
}

.overlay-hugeinc.open nav ul {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.overlay-hugeinc.close nav ul {
	-webkit-transform: translateY(25%) scale(1);
	transform: translateY(25%) scale(1);
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}
.overlay.open .menu-overlay-social {
    bottom: 4%;
    opacity: 1;
    -webkit-transition: bottom .25s ease-in-out .2s,opacity .25s ease-in-out .3s;
    -moz-transition: bottom .25s ease-in-out .2s,opacity .25s ease-in-out .3s;
    transition: bottom .25s ease-in-out .2s,opacity .25s ease-in-out .3s;
}
.overlay2.open .menu-overlay-social {
    bottom: 4%;
    opacity: 1;
    -webkit-transition: bottom .25s ease-in-out .2s,opacity .25s ease-in-out .3s;
    -moz-transition: bottom .25s ease-in-out .2s,opacity .25s ease-in-out .3s;
    transition: bottom .25s ease-in-out .2s,opacity .25s ease-in-out .3s;
}
.menu-overlay-social {
    position: absolute;
    width: 100%;
    height: 28px;
    left: 0;
    bottom: -4%;
    opacity: 0;
    -webkit-transition: bottom .25s ease-in-out,opacity .25s ease-in-out;
    -moz-transition: bottom .25s ease-in-out,opacity .25s ease-in-out;
    transition: bottom .25s ease-in-out,opacity .25s ease-in-out;
    text-align: center;
}

.menu-overlay-social ul li{
    float: left;
    margin: 0 15px;
}
.footer-nav-list, .footer-social-icons-list {
    list-style: outside none none;
    margin: 0;
    padding-left: 0;
}
.footer-social-icons-list li {
    display: inline-block;
    margin-left: 7px;
    margin-right: 8px;
    font-size: 20px;
    width: 25px;
    height: 25px;
}
.footer-social-icons-list a {
    line-height: inherit;
    width: 100%;
    height: 100%;
}
.footer-social-icons-list a .svg-wrapper svg {
    height: 40px;
    width: 40px;
    fill: currentColor;
}
